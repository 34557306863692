<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">人员列表</div>
        <el-button type="primary" @click="createUser" class="cardTitleBtn">新建人员</el-button>
        <el-button type="success" class="cardTitleBtn" @click="customExport" style="margin-right:5px">导出人员数据</el-button>
      </el-col>
      <el-col :span="24">
         <el-table :data="userList" style="width: 100%">
                <el-table-column prop="account" label="账号" sortable></el-table-column>
                <el-table-column prop="name" label="姓名" sortable></el-table-column>
                <el-table-column label="未完成任务数" sortable>
                  <template slot-scope="scope">
                    <span>{{scope.row.totalCount - scope.row.finishCount}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="totalCount" label="已分配任务数" sortable></el-table-column>
                <el-table-column prop="updateTime" label="最近修改时间" sortable></el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="getDetail(scope.row)"
                      type="primary"
                      size="mini"
                    >
                      编辑
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      userList:[],
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserList()
  },
  methods: {
    getUserList() {
      let self = this;
      self.$axios
        .get("/staff/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.userList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "用户列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "staff_detail", query: { guid: row.guid } });
    },
    createUser() {
      let self = this;
      self.$router.push({ name: "staff_detail", query: { guid: null } });
    },
    //  handleSizeChange(val) {
    //   this.pageSize = val * 1;
    //   this.getUserPageNum()
    // },
    // handleCurrentChange(val) {
    //   this.getUserPageNum(val)
    // },
    // getUserPageNum(val) {
    //   this.getUserList(val);
    // },
    customExport() {
      let url = this.GLOBAL.host + "/staff/download";
      var a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
    },
  },
};
</script>
